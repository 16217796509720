<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/organization/created' }"
        >组织创建</el-breadcrumb-item
      >
    </el-breadcrumb>
    <el-card class="righttable">
      <i class="el-icon-postcard"></i>
      <span style="margin-left:10px;">创建记录</span>
      <div class="search-bar-container">
        <div class="search-bar">
          <el-input
            v-model="formInline.oname"
            placeholder="请输入组织名称进行查询"
            style="width:240px;"
            size="small"
            class="inputstyle"
            @keydown.enter.native="searchOrg"
            suffix-icon="el-icon-search"
          >
          </el-input>
          <el-button plain type="primary" size="small" @click="clearSearch"
            >重置</el-button
          >
          <el-button type="primary" size="small" @click="searchOrg"
            >查询</el-button
          >
        </div>
      </div>
      <el-button
        type="primary"
        size="small"
        @click="handleClick"
        style="position:reletive;float:right;margin-right:10px;margin-bottom:20px;"
        >创建</el-button
      >
      <el-table
        :data="organizationHistory"
        style="width:100%;"
        fit
        border
        stripe
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织码"
          prop="ocode"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织单位名称"
          prop="oname"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="createtime"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="small"
              @click="openModify(scope.row)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog title="组织创建" :visible.sync="dialogVision" width="45%">
      <div class="content">
        <el-form :model="createdForm" :rules="rules" ref="createdForm">
          <el-form-item prop="phone">
            <el-input
              v-model="createdForm.phone"
              placeholder="请输入联系号码"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-mobile"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="createdForm.email"
              placeholder="请输入邮箱"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-message"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input
              v-model="createdForm.address"
              placeholder="请输入地址"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-location"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="oname">
            <el-input
              v-model="createdForm.oname"
              placeholder="请输入要创建的组织名称"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-office-building"></i> </template
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closedialogVision('createdForm')">取 消</el-button>
        <el-button type="primary" @click="createdOrganization('createdForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="组织修改" :visible.sync="modifyDialogVision" width="45%">
      <div class="content">
        <el-form :model="modifyForm" :rules="rules" ref="modifyForm">
          <el-form-item prop="phone">
            <el-input
              v-model="modifyForm.phone"
              placeholder="请输入联系号码"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-mobile"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="modifyForm.email"
              placeholder="请输入邮箱"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-message"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input
              v-model="modifyForm.address"
              placeholder="请输入地址"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-location"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="oname">
            <el-input
              v-model="modifyForm.oname"
              placeholder="请输入修改后的组织名称"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-office-building"></i> </template
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModifyDialog('modifyForm')">取 消</el-button>
        <el-button type="primary" @click="modifyOrganization('modifyForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  oname: null
}
export default {
  data() {
    return {
      uid: '', //用户id
      organizationHistory: [],
      formInline: {
        oname: '',
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      dialogVision: false, //创建
      modifyDialogVision: false, //修改
      createdForm: {
        phone: '',
        email: '',
        address: '',
        oname: ''
      },
      modifyForm: {},
      rules: {
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确的电话号码',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        oname: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid')
  },
  mounted() {
    this.getList()
  },
  methods: {
    //重置
    clearSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
      this.getList()
    },
    //查询
    async searchOrg() {
      const { data: res } = await this.$http.get('/org/selectbyoname', {
        params: {
          oname: this.formInline.oname,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize,
          uid: this.uid
        }
      })
      if (res.data != null) {
        this.organizationHistory = res.data
        this.total = res.total
        this.$message.success('查询成功')
      }
    },
    //打开修改框
    openModify(row) {
      this.modifyForm = { ...row }
      this.modifyDialogVision = true
    },
    //处理删除组织操作
    handleDelete(row) {
      this.$confirm('确定删除这个组织吗？', '提示', {
        confirmButtonText: '确实',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            const { data: res } = await this.$http.get('/org/deleorg', {
              params: {
                oid: row.oid
              }
            })
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          } catch (error) {
            console.error(error)
            this.$message.error('删除失败，请稍后重试')
          }
        })
        .catch(() => {
          this.$message.info('您已取消操作')
        })
    },

    //打开创建弹框
    handleClick() {
      this.dialogVision = true
    },
    //关闭创建组织弹框
    closedialogVision(formName) {
      this.createdForm.phone = ''
      this.createdForm.email = ''
      this.createdForm.address = ''
      this.createdForm.oname = ''
      this.dialogVision = false
      this.$refs[formName].resetFields() //重置表单状态
    },
    //关闭修改弹框
    closeModifyDialog(formName) {
      this.modifyDialogVision = false
    },
    //获取创建记录
    async getList() {
      const { data: res } = await this.$http.get('/org/selectbyuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.organizationHistory = res.data
        this.total = res.total
      }
    },
    //创建组织
    createdOrganization(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/org/createneworg',
            qs.stringify({
              address: this.createdForm.address,
              email: this.createdForm.email,
              oname: this.createdForm.oname,
              phone: this.createdForm.phone,
              uid: this.uid
            })
          )
          if (res.code === 200) {
            this.$message.success(res.message)
            this.showCreateSuccessAlert(res.data)
            this.getList()
          } else {
            this.$message.error('提交错误')
          }
          this.createdForm.phone = ''
          this.createdForm.email = ''
          this.createdForm.address = ''
          this.createdForm.oname = ''
          this.dialogVision = false
          this.$refs[formName].resetFields() //重置表单状态
        } else {
          return false
        }
      })
    },
    //修改组织
    modifyOrganization(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/org/updateorg',
            qs.stringify({
              address: this.modifyForm.address,
              email: this.modifyForm.email,
              oname: this.modifyForm.oname,
              phone: this.modifyForm.phone,
              oid: this.modifyForm.oid
            })
          )
          if (res.code === 200) {
            this.getList()
            this.closeModifyDialog(formName)
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          } else {
            this.$message({
              type: 'error',
              message: '修改失败'
            })
          }
        } else {
          return false
        }
      })
    },
    //更改分页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    //更改到第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    },
    //显示创建成功的弹框
    showCreateSuccessAlert(code) {
      this.$alert(
        `创建成功！您可以将该组织码${code}分享给其他人，邀请他加入您的组织`,
        '提示',
        {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'success',
              message: '已确定信息'
            })
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.createdInput {
  width: 300px;
}
.el-dialog {
  box-sizing: border-box;
}
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
}

.el-dialog__body {
  padding: 20px;
}

.el-dialog__footer {
  padding: 10px 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
}
.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .search-bar {
    .inputstyle {
      margin-right: 10px;
    }
  }
}
.el-button {
  margin: 10px;
}
</style>
